@import url('https://fonts.googleapis.com/css2?family=Marck+Script&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Grenze+Gotisch:wght@200;300;400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,wght@0,200;0,300;0,400;0,900;1,600&family=Tajawal:wght@400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Sanchez&display=swap');

@font-face {
    font-family: 'Tangerine';
    src: url('../assets/fonts/Tangerine-Regular.ttf');
}


.error-wrapper{
    width: 350px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2rem;
    margin-bottom: 2rem;
    border-radius: 20px;
    box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
    padding: 1rem;    
}

.error-img{
    width: fit-content;
    display: flex;
    margin: auto;
    color: red;
    font-size: 30px;
}

.error-msg{
    text-align: center;
    margin-top: 15px;
    margin-bottom: 15px;
    font-size: 20px;
}

.error-contact{
    display: flex;
    text-align: center;
    color: white;
    margin: auto;
    font-size: 22px;
    width: max-content;
    background-color: rgb(46, 86, 118);
    border-radius: 50px;
    padding-right: 1rem;
    padding-left: 1rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}

.btn{
    width: fit-content;
    margin:20px auto;

    padding:1rem 4rem;
    cursor: pointer;
    border-radius: 12px;
    background-color: rgb(79 166 107);
    color: white;
    font-size: 25px;
    box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
}